@import '../../common.scss';

.hp-layoutV2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: var(--mainContentHeight);
  max-width: var(--vw);
  position: relative;
  @at-root #interactiveBook {
    height: var(--vmin);
    display: flex;
    align-items: center;
    justify-content: center;
		#mainContentScreen {position: relative;}
  }
  .hp-topBanner {
    display: none;
  }
  .hp-clipLoader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      position: relative !important;
    }
  }
  .hp-headerArea {
    top: 0;
    left: 0;
    position: absolute;
    height: var(--headerHeight);
    width: var(--leftAreaMinWidth);
    display: flex;
    justify-content: center;
  }
  .hp-leftArea {
    flex: 1;
    min-width: var(--leftAreaMinWidth);
    height: calc(var(--mainContentHeight) - var(--headerHeight));
    margin-top: var(--headerHeight);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .ks-content {
    margin-right: 0;
    background-color: black;
    border-radius: responsiveSizeLegacyToV2(18);
    width: var(--mainContentWidth);
    height: var(--mainContentHeight);
    overflow: hidden;
  }
  &.fullscreen {
    .hp-leftArea,
    .hp-headerArea {
      display: none;
    }
  }
}


.dark {
	.ib-book-page {
		&__text {
			background: linear-gradient(
				180deg,
				rgba(78, 100, 151, 1) 0%,
				rgba(47, 132, 149, 1) 100%
			);
			svg {
				#TEXTE_BAS {
					path {
						fill: #ffd890;
					}
				}
				#TEXTE_HAUT {
					path {
						fill: white;
					}
				}
			}
		}
	}
}

.portrait {
  .hp-layoutV2 {
    flex-direction: column-reverse;
    height: var(--vmin);
  }
  .hp-leftArea {
    width: 100%;
    margin-top: 0;
    display: block;
  }
  .ks-content {
    margin-left: 0;
    margin-right: 0;
    margin-top: var(--headerHeight);
  }
}

.fullscreen {
	.light {
		.ib-book-page-audio__top {
			background-color: #52679a;
			.ib-audio-playing {
				.css-0 {
					width: responsiveSizeLegacyToV2(60);
					display: flex;
					justify-content: center;
					.css-110do72,
					.css-1by9rry {
						width: responsiveSizeLegacyToV2(13);
						height: responsiveSizeLegacyToV2(13);
						background-color: #ffffff;
					}
				}
			}
			svg {
				g {
					ellipse {
						display: none;
					}
					path {
						fill: #ffffff;
					}
				}
			}
		}
		.ib-book-page-audio__bottom {
			background-color: #9f721e;
			.ib-audio-playing {
				.css-0 {
					width: responsiveSizeLegacyToV2(60);
					display: flex;
					justify-content: center;
					.css-110do72,
					.css-1by9rry {
						width: responsiveSizeLegacyToV2(13);
						height: responsiveSizeLegacyToV2(13);
						background-color: #ffffff;
					}
				}
			}
			svg {
				g {
					ellipse {
						display: none;
					}
					path {
						fill: #ffffff;
					}
				}
			}
		}
	}
	.dark {
		.ib-book-page-audio__top {
			background-color: #ffffff;
			.ib-audio-playing {
				.css-0 {
					width: responsiveSizeLegacyToV2(60);
					.css-110do72,
					.css-1by9rry {
						width: responsiveSizeLegacyToV2(13);
						height: responsiveSizeLegacyToV2(13);
						background-color: #52679a;
					}
				}
			}
			svg {
				g {
					ellipse {
						display: none;
					}
					path {
						fill: #52679a;
					}
				}
			}
		}

		.ib-book-page-audio__bottom {
			background-color: #ffffff;
			.ib-audio-playing {
				width: responsiveSizeLegacyToV2(60);
				.css-0 {
					.css-110do72,
					.css-1by9rry {
						width: responsiveSizeLegacyToV2(13);
						height: responsiveSizeLegacyToV2(13);
						background-color: #9f721e;
					}
				}
			}
			svg {
				g {
					ellipse {
						display: none;
					}
					path {
						fill: #9f721e;
					}
				}
			}
		}
	}
}
