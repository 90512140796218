@import "./common.scss";

html,
body {
	overflow: hidden;
	position: relative;
	height: fit-content;
}

button.mainBtn {
	font-family: "Fredoka One";
	background-color: #51aabc;
	&:focus {
		outline: 0;
	}
	cursor: pointer;
	@include responsive-size(border-radius, 30);
	@include responsive-size(padding-left, 16);
	@include responsive-size(padding-right, 16);
	@include responsive-size(padding-top, 6);
	@include responsive-size(padding-bottom, 6);
	border: none;
	color: white;
	&:disabled {
		opacity: 0.4;
	}
}

#interactiveBook {
	height: 100vh;
}

.ms-clickable,
.ib-clickable {
	cursor: pointer;
}

.ib-unallowed {
	cursor: not-allowed;
}

.ib-leftPanel {
	width: 10.6%;
	z-index: 20;
	background-color: black;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-family: "Inter";
	color: white;
	@include responsive-size(padding-top, 13);
	> div {
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.ib-avatar {
		margin-left: auto;
		margin-right: auto;
		> div {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;
			margin-right: auto;
			@include responsive-size(border-radius, 115/2);
			@include responsive-size(width, 115);
			@include responsive-size(height, 115);
			background-color: #efe0bf;
			svg {
				@include responsive-size(width, 73.44);
				@include responsive-size(height, 59.44);
			}
		}
	}

	svg.ib-add-profil {
		@include responsive-size(width, 115);
		@include responsive-size(height, 115);
	}
	.ib-usnername {
		border: solid white;
		text-align: center;
		@include responsive-size(width, 125);
		@include responsive-size(height, 39);
		@include responsive-size(font-size, 18);
		@include responsive-size(margin-top, 18);
		@include responsive-size(margin-bottom, 15.5);
		@include responsive-size(border-width, 2);
		@include responsive-size(border-radius, 30);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.ib-letter-cards {
	width: 100%;
	display: flex;
	position: relative;
	justify-content: space-evenly;
	@include responsive-size(height, 423);
	.ib-card {
		& > div,
		svg,
		img {
			@include responsive-size(width, 326);
			@include responsive-size(height, 420);
		}
	}
	.ib-letterCard {
		z-index: 2;
	}
	.ib-illustrationCard {
		z-index: 1;
		position: absolute;
		&.ib-illustration1 {
			transform: translateX(0);
			&.ib-display {
				transition: all 1s;
				transform: translateX(-107%);
				-webkit-transform: translateZ(0);
			}
			&.ib-display-no-transition {
				transition: none;
				transform: translateX(-107%);
			}
		}
		&.ib-illustration2 {
			transform: translateX(0);
			&.ib-display {
				transition: all 1s;
				-webkit-transform: translateZ(0);
				transform: translateX(107%);
			}
			&.ib-display-no-transition {
				transition: none;
				transform: translateX(107%);
			}
		}
	}
}

.ib-navigation-next {
	transform: rotate(180deg);
}

.ib-disabled,
.ib-not-selected {
	opacity: 0.3;
}

.ib-disabled {
	cursor: not-allowed;
}

.ib-helpBtn {
	position: absolute;
	right: 1.2%;
	bottom: 2%;
	@include responsive-size(width, 35);
	@include responsive-size(height, 35);
}

.ib-progression-flag-letter,
.ib-progression {
	position: absolute;
	bottom: -0.2%;

	width: 100%;
	@include responsive-size(height, 270);
	.ib-backwave,
	.ib-frontwave,
	.ib-boat,
	.ib-flag {
		position: absolute;
		svg {
			@include responsive-size(width, 72.29);
			@include responsive-size(height, 158.36);
		}
	}

	.ib-backwave,
	.ib-frontwave {
		svg {
			@include responsive-size(width, 1365);
			@include responsive-size(height, 77.586);
		}
	}
	.ib-backwave {
		z-index: 1;
		@include responsive-size(bottom, 25.2);
	}
	.ib-boat {
		z-index: 3;
		transition: all 1s;
		@include responsive-size(left, -15);
		@include responsive-size(left, -15);
		@include responsive-size(bottom, 50);
		&,
		svg {
			@include responsive-size(width, 323.45);
			height: fit-content;
		}
	}
	.ib-frontwave {
		z-index: 4;
		bottom: 0;
		@media (pointer: none), (pointer: coarse) {
			bottom: -0.4%;
		}
	}
	.ib-flag {
		right: 2.5%;
		bottom: 9%;
		z-index: 2;
	}
}

.ib-progression-flag-letter {
	height: 35%;
	position: absolute;
	bottom: 0;
	width: 100%;
	.ib-boat {
		left: 0;
		svg {
			@include responsive-size(width, 192.94);
			@include responsive-size(height, 165.32);
		}
	}
	.ib-flag {
		top: auto;
		svg {
			@include responsive-size(width, 127.52);
			@include responsive-size(height, 345.57);
		}
		&.ib-flag-left {
			left: 0;
			right: auto;
		}
		&.ib-flag-middle {
			left: 45.7%;
			right: auto;
		}
		&.ib-flag-right {
			right: 0;
		}
		&.ib-flag-select {
			svg {
				g[fill="#fff"] {
					fill: #51aabc;
				}
				path[id^="letter"],
				text[id^="letter"],
				path[id^="moon"],
				g[id="sun"],
				path[fill="gray"],
				path[fill="#c3c3c3"] {
					fill: white;
				}
				g[id="sun"] {
					path {
						fill: white;
					}
				}
			}
		}
	}
}

.ib-navigation-play {
	display: flex;
	align-items: center;
	justify-content: center;
	@include responsive-size(margin-top, 30);
	div[class^="ib-navigation"] svg {
		@include responsive-size(width, 68.07);
		@include responsive-size(height, 72.79);
		path {
			fill: #51aabc;
		}
	}
	div.ib-disabled-arrow {
		svg path {
			fill: #dddddd;
		}
	}
	.ib-play {
		@include responsive-size(margin-left, 49);
		@include responsive-size(margin-right, 49);
		svg {
			@include responsive-size(width, 90);
			@include responsive-size(height, 90);
		}
	}
}

svg {
	cursor: default;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ib-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	.ib-clickable & {
		cursor: pointer;
	}
}

a {
	outline: none;
	text-decoration: none;
}
