@import "../../common.scss";

.ib-home-icon {
	bottom: responsiveSizeLegacyToV2(601);
	width: responsiveSizeLegacyToV2(325.53);
	height: responsiveSizeLegacyToV2(201.65);
	display: flex;
	flex: 1;
	svg {
		width: 100%;
		height: 100%;
	}
}

