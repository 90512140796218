@import "../../../common.scss";

.hp-layoutV2 {
	.ib-background {
		z-index: 0;
		position: absolute;
		background-image: url("../../../assets/v2/ib-home-background.svg");
		background-repeat: no-repeat;
		background-size: cover;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.ib-bd-sidebar {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: responsiveSizeLegacyToV2(442);
		background: linear-gradient(
			0deg,
			rgba(12, 89, 103, 0.4) 0%,
			rgba(6, 45, 52, 0.4) 100%
		);
		&__books-library {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			margin-top: responsiveSizeLegacyToV2(146);
			margin-left: responsiveSizeLegacyToV2(37.5);
			width: responsiveSizeLegacyToV2(375);
			height: 100%;
			max-height: responsiveSizeLegacyToV2(801);
			overflow-y: scroll;
			overflow-x: hidden;
			&::-webkit-scrollbar {
				-ms-overflow-style: none;
				scrollbar-width: none;
				display: none;
			}
			.overlay {
				display: none;
			}
			&--item {
				position: relative;
			}
			&--item,
			&--thumbnail {
				svg {
					width: responsiveSizeLegacyToV2(367);
					height: responsiveSizeLegacyToV2(174);
					cursor: pointer;
				}
			}
			&--active {
				svg {
					border: solid white;
					border-width: responsiveSizeLegacyToV2(4);
					border-radius: responsiveSizeLegacyToV2(15);
				}
			}
			&--disabled {
				opacity: 0.5;
				svg {
					cursor: not-allowed;
					pointer-events: none;
				}
			}
			&--blocked {
				.overlay {
					display: block;
					cursor: pointer;
					position: absolute;
					z-index: 2;
					top: 0;
					left: 0;
					width: responsiveSizeLegacyToV2(367);
					height: responsiveSizeLegacyToV2(174);
					border-radius: responsiveSizeLegacyToV2(12);
					background-color: rgba($color: #51AABC, $alpha: 0.6);
					svg {
						position: absolute;
						width: responsiveSizeV2(58);
						height: responsiveSizeV2(58);
						right: responsiveSizeLegacyToV2(7);
						top: responsiveSizeLegacyToV2(7);
					}
					&.selected {
						left: responsiveSizeLegacyToV2(4);
						top: responsiveSizeLegacyToV2(4);
					}
				}
			}
		}
	}

	.ib-bd {
		position: absolute;
		width: responsiveSizeLegacyToV2(650);
		height: responsiveSizeLegacyToV2(981);
		top: responsiveSizeLegacyToV2(77.4);
		left: responsiveSizeLegacyToV2(239.7);
		display: flex;
		justify-content: space-between;
		&__item-container {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			width: responsiveSizeLegacyToV2(650);
			height: responsiveSizeLegacyToV2(981);
			&__cover-box {
				width: responsiveSizeLegacyToV2(650);
				height: responsiveSizeLegacyToV2(874);
				img {
					width: 100%;
					height: 100%;
					border-radius: responsiveSizeLegacyToV2(28);
				}
			}
			button {
				width: responsiveSizeLegacyToV2(240);
				height: responsiveSizeLegacyToV2(74);
				border-radius: responsiveSizeLegacyToV2(42);
				border-width: responsiveSizeLegacyToV2(4);
				border: solid #ffffff;
				font-size: responsiveSizeLegacyToV2(40);
				text-transform: uppercase;
				background-color: #51aabc;
				font-family: "Fredoka One";
				color: #ffffff;
				cursor: pointer;
			}
			.not-allow {
				filter: grayscale(100%);
				&--btn {
					@include responsive-size(width, 350);
					background-color: #c3c3c3;
				}
			}
		}
	}
}

.portrait {
	.ib-bd-sidebar {
		top: initial;
		right: initial;
		bottom: responsiveSizeLegacyToV2(20);
		width: 100%;
		height: responsiveSizeLegacyToV2(255);
		background: initial;
		&__books-library {
			flex-direction: row;
			width: 100%;
			height: 100%;
			margin: initial;
			overflow-y: hidden;
			overflow-x: scroll;
			&::-webkit-scrollbar {
				-ms-overflow-style: none;
				scrollbar-width: none;
				display: none;
			}
			&--thumbnail {
				margin-left: responsiveSizeLegacyToV2(40);
				svg {
					width: responsiveSizeLegacyToV2(442);
					height: responsiveSizeLegacyToV2(224);
				}
			}
			&--active {
				svg {
					border: none;
					border-width: 0;
					border-radius: 0;
				}
			}
		}
	}

	.ib-bd {
		width: responsiveSizeLegacyToV2(675);
		height: responsiveSizeLegacyToV2(1129.76);
		top: responsiveSizeLegacyToV2(83);
		left: 50%;
		transform: translateX(-50%);
		&__item-container {
			width: 100%;
			height: 100%;
			&__cover-box {
				width: 100%;
				height: responsiveSizeLegacyToV2(977);
			}
		}
	}
}
