@import "../../common.scss";

.controller {
	z-index: 2;
	position: absolute;
	display: flex;
	align-items: center;
	border-radius: responsiveSizeLegacyToV2(50);
	height: responsiveSizeLegacyToV2(99);
	background-color: white;
	min-width: responsiveSizeLegacyToV2(281);
	&--left {
		position: initial;
		justify-content: space-evenly;
		width: responsiveSizeLegacyToV2(281);
	}
	&--main {
		justify-content: space-evenly;
		bottom: responsiveSizeLegacyToV2(63);
		right: responsiveSizeLegacyToV2(131);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		width: responsiveSizeLegacyToV2(454);
	}
	&--prev-btn,
	&--next-btn {
		fill: #51aabc;
		cursor: pointer;
		width: responsiveSizeLegacyToV2(55);
		height: responsiveSizeLegacyToV2(55);
	}
	&--next-btn {
		transform: rotate(180deg);
	}
	&--play-btn {
		cursor: pointer;
		color: #51aabc;
		width: auto;
		height: responsiveSizeLegacyToV2(68.6);
	}
	&--main-theme-icon {
		cursor: pointer;
		width: responsiveSizeLegacyToV2(68.68);
		height: responsiveSizeLegacyToV2(68.68);
	}
	&--reduce-btn {
		cursor: pointer;
		width: responsiveSizeLegacyToV2(59.29);
		height: responsiveSizeLegacyToV2(59.29);
		path {
			fill: black;
		}
	}
	&--separator {
		height: responsiveSizeLegacyToV2(68);
		width: responsiveSizeLegacyToV2(3);
		background-color: #c3c3c3;
	}

	&--btn-disabled {
		fill: #c3c3c3;
		cursor: not-allowed;
		pointer-events: none;
	}
	&__dark-container {
		position: absolute;
		background-color: black;
		width: responsiveSizeLegacyToV2(199);
		height: responsiveSizeLegacyToV2(99);
		right: responsiveSizeLegacyToV2(33);
		bottom: responsiveSizeLegacyToV2(63);
		border-radius: responsiveSizeLegacyToV2(50);
		color: white;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		&--exit-btn {
			cursor: pointer;
			width: responsiveSizeLegacyToV2(55);
			height: responsiveSizeLegacyToV2(55);
			margin-right: responsiveSizeLegacyToV2(31.7);
		}
	}
}

// *********** Mobile Version **********

.portrait {
	.controller {
		&--main-theme-icon {
			width: responsiveSizeLegacyToV2(120.99);
			height: responsiveSizeLegacyToV2(120.99);
		}
		&--prev-btn,
		&--next-btn {
			width: responsiveSizeLegacyToV2(106);
			height: responsiveSizeLegacyToV2(106);
		}
		&--play-btn {
			width: responsiveSizeLegacyToV2(120.87);
			height: responsiveSizeLegacyToV2(120.87);
		}
		&--separator {
			height: responsiveSizeLegacyToV2(119.8);
		}
		&--content-icon {
			width: responsiveSizeLegacyToV2(91.41);
			height: responsiveSizeLegacyToV2(119.8);
		}
		&__dark-container {
			&--logout-btn {
				width: responsiveSizeLegacyToV2(91.74);
				height: responsiveSizeLegacyToV2(91.74);
			}
		}
	}
}
