@import "../../common.scss";

.ib-bookpage-leftarea-infos {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: responsiveSizeLegacyToV2(281);
	height: responsiveSizeLegacyToV2(640.93);
	position: absolute;
	bottom: responsiveSizeLegacyToV2(295);
	color: white;
	&__chapter {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: responsiveSizeLegacyToV2(181.58);
		height: responsiveSizeLegacyToV2(227.4);
		font-family: "Itim";
		font-size: responsiveSizeLegacyToV2(19);
		svg {
			width: responsiveSizeLegacyToV2(181.58);
			height: responsiveSizeLegacyToV2(199.83);
		}
		p {
			margin: 0;
			text-align: center;
		}
	}
	&__audios {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		width: responsiveSizeLegacyToV2(259.35);

		&--left,
		&--right {
			width: responsiveSizeLegacyToV2(118.85);
			height: responsiveSizeLegacyToV2(118.85);
			svg {
				width: 100%;
				height: 100%;
			}
		}
		&--left {
			.ib-audio-playing {
				display: flex;
				align-items: center;
				justify-content: center;
				width: responsiveSizeLegacyToV2(118.85);
				height: responsiveSizeLegacyToV2(118.85);
				background-color: white;
				border-radius: 100%;
				cursor: pointer;

				.css-0 {
					.css-110do72,
					.css-1by9rry {
						background-color: #52679a;
						width: responsiveSizeLegacyToV2(25);
						height: responsiveSizeLegacyToV2(25);
						margin: responsiveSizeLegacyToV2(4);
					}
				}
			}
			ellipse {
				fill: #52679a;
			}
		}
		&--right {
			.ib-audio-playing {
				display: flex;
				align-items: center;
				justify-content: center;
				width: responsiveSizeLegacyToV2(118.85);
				height: responsiveSizeLegacyToV2(118.85);
				background-color: white;
				border-radius: 100%;
				cursor: pointer;
				.css-0 {
					.css-110do72,
					.css-1by9rry {
						background-color: #9f721e;
						width: responsiveSizeLegacyToV2(25);
						height: responsiveSizeLegacyToV2(25);
						margin: responsiveSizeLegacyToV2(4);
					}
				}
			}
			ellipse {
				fill: #9f721e;
			}
		}
	}
	&__theme {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: responsiveSizeLegacyToV2(241);

		&--on,
		&--off {
			cursor: pointer;
			width: responsiveSizeLegacyToV2(106);
			height: responsiveSizeLegacyToV2(106);
		}
		&--off {
			opacity: 0.5;
		}
	}
}

.ib-bookpage-leftarea-buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: responsiveSizeLegacyToV2(281);
	height: responsiveSizeLegacyToV2(242);
	position: absolute;
	bottom: responsiveSizeLegacyToV2(21);
	color: white;
	font-family: "Fredoka One";
	font-size: responsiveSizeLegacyToV2(24);
	text-transform: uppercase;
	a {
		color: white;
	}
	&__btn {
		display: flex;
		align-items: center;
		width: 100%;
		height: responsiveSizeLegacyToV2(62);
		border-radius: responsiveSizeLegacyToV2(42);
		border-width: responsiveSizeLegacyToV2(3);
		border: solid white;
		cursor: pointer;
		svg {
			cursor: pointer;
			margin-right: responsiveSizeLegacyToV2(15.5);
			margin-left: responsiveSizeLegacyToV2(28);
		}
		.icon-home {
			width: responsiveSizeLegacyToV2(34);
			height: responsiveSizeLegacyToV2(34);
		}
		.icon-chapters {
			width: responsiveSizeLegacyToV2(36);
			height: responsiveSizeLegacyToV2(36);
		}
		.icon-fullscreen {
			width: responsiveSizeLegacyToV2(36.48);
			height: responsiveSizeLegacyToV2(36.48);
		}
	}
}
