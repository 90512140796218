@import "../../../common.scss";

.standalone {
	position: absolute;
	top: 0;
	z-index: 9;
	background-color: #ffff;
	width: 100%;
	height: 100%;
	.bg-support {
		display: none;
	}
	.ib-book-page {
		display: flex;
		position: absolute;
		height: 100%;
		width: 100%;
		&__image {
			position: relative;
			margin-top: 0;
			margin-left: 0;
			height: 100%;
			img {
				height: 100%;
			}
		}

		&__text {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				height: 100%;
				width: responsiveSizeLegacyToV2(652);
			}
		}
	}
	.disable {
		fill: #c3c3c3;
		cursor: disabled;
		pointer-events: none;
	}

	.disable-audio {
		cursor: disabled;
		pointer-events: none;
	}
	.ib-book-page-audio {
		position: absolute;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: responsiveSizeLegacyToV2(555);
		top: 50%;
		transform: translateY(-50%);
		display: none;

		&__top,
		&__bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			@include responsive-size(width, 57);
			@include responsive-size(height, 114);
			@include responsive-size(border-top-left-radius, 57);
			@include responsive-size(border-bottom-left-radius, 57);
			cursor: pointer;
		}
	}
	.page-index {
		display: block;
		position: absolute;
		right: responsiveSizeLegacyToV2(300);
		bottom: responsiveSizeLegacyToV2(14);
		font-size: responsiveSizeLegacyToV2(34);
		margin: 0;
		font-family: "Muséo";
		color: #c3c3c3;
	}
}

.fullscreen {
	.ib-book-page {
		&__image {
			width: responsiveSizeLegacyToV2(825);
			height: responsiveSizeLegacyToV2(1168);
			img {
				width: 100%;
				height: 100%;
			}
		}
		&__text {
			width: responsiveSizeLegacyToV2(734);
			height: responsiveSizeLegacyToV2(1168);

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.ib-book-page-audio {
		display: flex;
	}
}

// ************* Mobile Version ************

.portrait {
	.standalone {
		background-image: url("../../../assets/v2/ib-home-background.svg");
		background-repeat: no-repeat;
		background-size: cover;
		.ib-book-page {
			width: responsiveSizeLegacyToV2(919);
			height: responsiveSizeLegacyToV2(1300);
			top: responsiveSizeLegacyToV2(40);
			left: 50%;
			transform: translateX(-50%);
			border-radius: responsiveSizeLegacyToV2(35);
			border-width: responsiveSizeLegacyToV2(5);
			border: solid black;
			background-color: white;
			&__image {
				width: 100%;
				height: 100%;
				border-radius: responsiveSizeLegacyToV2(35);
				img {
					width: 100%;
					height: 100%;
				}
			}
			&__text {
				display: initial;
				align-items: initial;
				justify-content: initial;
				svg {
					width: responsiveSizeLegacyToV2(919);
					height: responsiveSizeLegacyToV2(1300);
				}
			}
		}
		.main-mobile {
			width: responsiveSizeLegacyToV2(800);
			height: responsiveSizeLegacyToV2(174);
			left: 47%;
			bottom: responsiveSizeLegacyToV2(20);
			transform: translateX(-53%);
			border-width: responsiveSizeLegacyToV2(6);
			border: solid black;
		}
		.controller {
			&__dark-container {
				position: absolute;
				width: responsiveSizeLegacyToV2(919);
				left: 50%;
				transform: translateX(-50%);
				height: responsiveSizeLegacyToV2(174);
				right: 0;
				bottom: responsiveSizeLegacyToV2(20);
				border-width: responsiveSizeLegacyToV2(6);
				border: solid black;
			}
		}
	}
}
