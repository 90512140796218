@import "../../common.scss";

.btn {
	width: responsiveSizeLegacyToV2(198);
	height: responsiveSizeLegacyToV2(70);
	border-radius: responsiveSizeLegacyToV2(42);
	border-width: responsiveSizeLegacyToV2(4);
	font-size: responsiveSizeLegacyToV2(18);
	border: solid white;
	color: white;
	box-shadow: 0px responsiveSizeLegacyToV2(2) responsiveSizeLegacyToV2(6) 0px rgba(0, 0, 0, 0.16);
	cursor: pointer;
	text-transform: uppercase;
	font-family: "Fredoka One";
	&--blue {
		background-color: #51aabc;
	}
	&--violet {
		background-color: #8c86c1;
	}
	&--dark {
		background-color: black;
	}
}

.disabled {
	opacity: 0.3;
	cursor: not-allowed;
}

.play-icon {
	width: responsiveSizeLegacyToV2(63);
	height: responsiveSizeLegacyToV2(63);
}

.home-icon {
	width: responsiveSizeLegacyToV2(33);
	height: responsiveSizeLegacyToV2(33);
	margin-right: responsiveSizeLegacyToV2(16.4);
}
