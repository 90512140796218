@import "../../common.scss";

.ib-indexbook-leftarea {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: responsiveSizeLegacyToV2(325);
	flex: 1;
	&__thumbnails {
		width: 100%;
		height: auto;
		img {
			width: 100%;
			height: 100%;
			border-radius: responsiveSizeLegacyToV2(13);
		}
	}
	&__authors {
		font-family: "Inter";
		font-size: responsiveSizeLegacyToV2(18);
		color: #dddddd;
	}
}
