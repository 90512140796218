@import "../../../common.scss";

.ib-index-book-background {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: responsiveSizeLegacyToV2(1325);
	height: responsiveSizeLegacyToV2(927);
}

.ib-index-book {
	display: flex;
	position: absolute;
	top: responsiveSizeLegacyToV2(190);
	left: responsiveSizeLegacyToV2(135);
	align-items: flex-start;
	justify-content: space-between;
	width: responsiveSizeLegacyToV2(1256.87);
	height: responsiveSizeLegacyToV2(725.61);
	&__left,
	&__right {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__left {
		width: responsiveSizeLegacyToV2(594.02);
	}
	&__right {
		width: responsiveSizeLegacyToV2(387.8);
	}
	&__btn-container {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: responsiveSizeLegacyToV2(270);
		margin-top: responsiveSizeLegacyToV2(230.3);
	}
}

.ib-index-book-item {
	width: responsiveSizeLegacyToV2(181.58);
	height: responsiveSizeLegacyToV2(227.4);
	font-size: responsiveSizeLegacyToV2(19);
	margin-bottom: responsiveSizeLegacyToV2(21.7);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	font-family: "Itim";
	cursor: pointer;
	text-decoration: none;
	color: #000000;
	outline: none;
	&--img {
		cursor: pointer;
		position: relative;
		width: responsiveSizeLegacyToV2(181.58);
		height: responsiveSizeLegacyToV2(199.83);
		svg {
			height: 100%;
			width: 100%;
			cursor: pointer;
		}
	}
	&.soon-text {
		svg {
			cursor: not-allowed;
		}
	}

	p {
		margin: 0;
	}
}
.ib-index-book-item:nth-last-of-type(1) {
	margin-bottom: 0;
}
.ib-index-book-item:nth-last-of-type(2) {
	margin-bottom: 0;
}
.ib-index-book-item:nth-last-of-type(3) {
	margin-bottom: 0;
}

.soon {
	cursor: not-allowed;
	position: relative;
	opacity: 0.4;
}
// ************ Mobile Version ****************

.ib-index-book-mobile {
	z-index: 0;
	position: absolute;
	background-image: url("../../../assets/v2/ib-home-background.svg");
	background-repeat: no-repeat;
	background-size: cover;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	&__book {
		position: relative;
		left: 50%;
		top: responsiveSizeLegacyToV2(59);
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: responsiveSizeLegacyToV2(497.19);
		height: responsiveSizeLegacyToV2(574.61);
		img {
			width: responsiveSizeLegacyToV2(296);
			height: responsiveSizeLegacyToV2(427);
			border-radius: responsiveSizeLegacyToV2(16);
		}
		&--btn {
			width: responsiveSizeLegacyToV2(497.19) !important;
			height: responsiveSizeLegacyToV2(117.76) !important;
			border-radius: responsiveSizeLegacyToV2(59) !important;
			font-size: responsiveSizeLegacyToV2(40) !important;
			&-home {
				position: relative;
				left: 50%;
				top: responsiveSizeLegacyToV2(56);
				transform: translateX(-50%);
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
				}
				.icon-home {
					width: responsiveSizeLegacyToV2(72);
					height: responsiveSizeLegacyToV2(72);
					margin-right: responsiveSizeLegacyToV2(24.8);
				}
			}
		}
	}
	&__chapters {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: responsiveSizeLegacyToV2(124.8);
		background-color: rgba(21, 45, 59, 0.2);
		height: responsiveSizeLegacyToV2(651.86);
		&-title {
			margin: 0;
			color: black;
			background: transparent;
			font-family: "Fredoka One";
			font-size: responsiveSizeLegacyToV2(44);
			margin-top: responsiveSizeLegacyToV2(21);
			margin-bottom: responsiveSizeLegacyToV2(43.7);
		}
		&-list {
			width: 100%;
			display: flex;
			flex-direction: row;
			overflow-x: scroll;
			overflow-y: hidden;
			&::-webkit-scrollbar {
				-ms-overflow-style: none;
				scrollbar-width: none;
				display: none;
			}
			&--chapter {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: column;
				width: responsiveSizeLegacyToV2(407.13);
				height: responsiveSizeLegacyToV2(508.28);
				margin-right: responsiveSizeLegacyToV2(64.9);
				img {
					width: responsiveSizeLegacyToV2(407.13);
					height: responsiveSizeLegacyToV2(448.05);
					border-radius: responsiveSizeLegacyToV2(37);
					border-width: responsiveSizeLegacyToV2(3);
					border: solid white;
				}
				p {
					margin: 0;
					color: white;
					font-family: "Inter";
					font-size: responsiveSizeLegacyToV2(35);
				}
			}
			&--chapter:nth-child(1) {
				margin-left: responsiveSizeLegacyToV2(45);
			}
		}
	}
}
